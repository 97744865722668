<template>
  <atomic-icon
    v-if="isShowDropsAndWinsLabel"
    id="drops-and-wins-label-top-right"
    width="40"
    height="40"
    class="game-label"
    filled
  />
</template>

<script setup lang="ts">
  import type { IGame } from '~/types';

  interface IGameImageProps {
    gameInfo: IGame;
  }

  const props = defineProps<IGameImageProps>();

  const isShowDropsAndWinsLabel = computed(() => {
    return props.gameInfo?.labels?.some(label => label.name === 'dropswins');
  });
</script>

<style src="~/assets/styles/components/card/label.scss" lang="scss"></style>
